<template>
  <div class="product grey lighten-4">
    <SpecialProductInfoHeader :storename="shop_info.store_name" />
    <SpecialProductInfoImage :url="shop_info.image_url" />
    <SpecialProductInfoShare />
    <SpecialProductInfoShop
      :uuid="product_uuid"
      :shopinfo="shop_info"
      :canbuybiggerten="canbuy_bigger_ten"
      :canbuyarr="canbuy_arr"
      :selectnum="selected"
      :canbuy="canbuy"
    />
  </div>
</template>

<script>
  import SpecialProductInfoHeader from '../components/specialproductInfo/SpecialProductInfoHeader.vue';
  import SpecialProductInfoImage from '../components/specialproductInfo/SpecialProductInfoImage.vue';
  import SpecialProductInfoShare from '../components/specialproductInfo/SpecialProductInfoShare.vue';
  import SpecialProductInfoShop from '../components/specialproductInfo/SpecialProductInfoShop.vue';
  
  export default {
    name: 'SpecialProduct',
    components: {
      SpecialProductInfoHeader,
      SpecialProductInfoImage,
      SpecialProductInfoShare,
      SpecialProductInfoShop,
    },
    data: () => ({
      access_key: 'rIhTQzuOwZ54asxqRMNkYVF3n6UA7v9c',
      product_uuid: 0,
      shop_info: [],
      canbuy_bigger_ten: false,
      canbuy_arr: [],
      selected: '1',
      canbuy: 0,
    }),
    computed: {
    },
    created() {
    },
    methods: {
      getUID() {
        if (this.$route.query.uid != '' && this.$route.query.uid != undefined) {
          this.product_uuid = parseInt(this.$route.query.uid);
        } else {
          alert('尚未選擇商品');
          window.location.href = '/';
          return false;
        }
      },
      async getProductDetail() {
        await this.getUID();
        this.axios({
          method: 'post',
          url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/productions/product-detail`,
          data: {
            access_key: process.env.VUE_APP_ACCESSKEY,
            production_id: this.product_uuid,
          },
        })
          .then((response) => {
            // console.log(response.data);
            this.shop_info = response.data;
            sessionStorage.setItem('storeUUID', this.shop_info.store_uuid);
            this.canbuy = response.data.canbuy;
            if (parseInt(this.shop_info.canbuy) > 10) {
              this.canbuy_bigger_ten = true;
              for (let i = 1; i <= 9 ; i++) {
                this.canbuy_arr.push(i);
              }
              this.canbuy_arr.push('+10');
            } else {
              this.canbuy_bigger_ten = false;
              for (let i = 1; i <= parseInt(this.shop_info.canbuy); i++) {
                this.canbuy_arr.push(i);
              }
            }
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      },
    },
    mounted() {
      this.getProductDetail();
      sessionStorage.setItem('IsSpecialStore', 'true');
    },
    beforeDestroy() {
    },
  };
</script>

<style scoped lang="sass">
</style>