<template>
	<div class="ProductInfoImage">
		<div>
			<v-card
				class="mb-2"
				outlined
			>
				<v-img
					height="350"
					class="ProDetailsImg"
					:src="url" />
			</v-card>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'SpecialProductInfoImage',
    props: {
      url: String,
    },

    data: () => ({
    }),
    methods: {
    },
  }
</script>

<style lang="sass">
@media only screen and (min-width: 426px)
    .ProductInfoImage .ProDetailsImg .v-image__image.v-image__image--cover
        background-size: auto 100%
        background-color: #f8f8f8
</style>
