<template>
	<div>
		<div>
			<v-card
				class="mt-2"
			>
				<v-list-item>
					<v-list-item-title class="body-2 text-center">
						<v-avatar size="22px">
							<img
								src="https://shop.cheerlife.io/img/logo_a.png"
								alt="logo"
							>
						</v-avatar>
						{{ storename }}
					</v-list-item-title>
				</v-list-item>
			</v-card>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'SpecialProductInfoHeader',
    props: {
      storename: String,
    },

    data: () => ({
    }),
    methods: {
    },
  }
</script>

<style scoped lang="sass">
</style>
