import { render, staticRenderFns } from "./SpecialProduct.vue?vue&type=template&id=1b453602&scoped=true&"
import script from "./SpecialProduct.vue?vue&type=script&lang=js&"
export * from "./SpecialProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b453602",
  null
  
)

export default component.exports