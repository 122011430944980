<template>
  <div>
    <v-card class="mb-2" outlined>
      <div class="my-1">
        <v-btn class="grey--text text--darken-3" text @click="shareLink">
          <v-icon class="pr-1">mdi-share-variant</v-icon>
          {{ $t("ProductInfoShare_button1") }}
        </v-btn>
        <input
          readonly
          type="hidden"
          class="form-control copyLinkInput"
          :value="shareURL"
        />
      </div>
    </v-card>
    <!-- share copy info -->
    <v-snackbar
      v-model="show_area.showcopysnackbar"
      :multi-line="copymultiLine"
      :timeout="copysnackbartime"
      centered
    >
      <span v-html="copy_state"></span>
      <v-btn color="blue" text @click="show_area.showcopysnackbar = false">
        {{ $t("close_button") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "SpecialProductInfoShare",
  props: {},

  data: () => ({
    // 分享連結
    show_area: { showcopysnackbar: false },
    shareURL: "",
    copy_state: "",
    copymultiLine: true,
    copysnackbartime: 5000,
  }),
  methods: {
    // 分享
    initShareURL() {
      const url = window.location.href;
      const splitURL = url.split("&phone=");
      this.shareURL = `CheerLife 生活趣兒 / 有個商品推薦給您，趕快點連結購買！Recommend you this product, click the purchase link!\n${splitURL[0]}`;
    },
    shareLink() {
      const selectEl = document.getElementsByClassName("copyLinkInput")[0];
      if (selectEl.value === "") {
        this.copy_state = "複製失敗：無網址";
        this.show_area.showcopysnackbar = true;
        return false;
      } else {
        selectEl.setAttribute("type", "text");
        selectEl.select();
        try {
          const copysuccessful = document.execCommand("copy");
          this.copy_state = copysuccessful
            ? "已複製網址，現在您可轉貼此網址。<br />URL copied, Share with others."
            : `請複製並轉貼此網址。<br />URL copied, Share with others.'<input class="d-block showcopyLinkInput" value="${selectEl.value}" />`;
          this.show_area.showcopysnackbar = true;
        } catch (err) {
          this.copy_state = `複製失敗 ${err}`;
          this.show_area.showcopysnackbar = true;
        }
        // cancel select
        selectEl.setAttribute("type", "hidden");
        window.getSelection().removeAllRanges();
      }
    },
  },
  mounted() {
    this.initShareURL();
  },
};
</script>

<style scoped lang="sass"></style>
